import React from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import CardsContainer from "@global/cards/CardsContainer"
import Seo from "@elements/Seo"
import Block from "@global/layout/Block"
import Cta from "@global/cards/Cta"
import Banner from "@global/layout/Banner"

const KeepingHealthy = ({ data, pageContext: { title, parent } }) => {
  const { keepingHealthyBanner } = data.banner
  const { heading, caption, meta } = data.content.keepingHealthy
  const pages = data.allDatoCmsNutritionPage.nodes
  return (
    <Layout parent={parent} title={title}>
      <Seo
        title={meta.title}
        description={meta.desc}
        image={keepingHealthyBanner.url}
      />
      <Banner image={keepingHealthyBanner} />
      <Block padding="top" gutters={true} maxWidth="inner">
        <h1>{heading}</h1>
        <p>{caption}</p>
      </Block>
      <Block padding="top" gutters={true} maxWidth="list-inner">
        <CardsContainer>
          {pages.map(page => {
            return (
              <Cta
                id={page.id}
                key={`keeping-healthy-card-${page.id}`}
                image={page.banner}
                heading={page.title}
                link={{ title: page.title, path: page.path }}
              />
            )
          })}
        </CardsContainer>
      </Block>
    </Layout>
  )
}

export const query = graphql`
  query keepingHealthyQuery {
    banner: datoCmsGlobal {
      keepingHealthyBanner {
        gatsbyImageData(
          imgixParams: { w: "1280", h: "640", fit: "crop", auto: "true" }
        )
        url(imgixParams: { w: "1000", fit: "max", fm: "jpg" })
      }
    }
    allDatoCmsNutritionPage(sort: { fields: position }) {
      nodes {
        id
        title
        path
        slug
        banner {
          gatsbyImageData(imgixParams: { fit: "crop", w: "770", h: "460" })
        }
      }
    }
    content: contentYaml(language: { eq: "en-NZ" }) {
      keepingHealthy {
        meta {
          title
          desc
        }
        heading
        caption
      }
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
  }
`

export default KeepingHealthy
